var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(!_vm.loading)?_c('div',{staticClass:"row form__body--card form__common--center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-6"},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticStyle:{"position":"absolute","left":"100%","top":"5px"}},[_c('company',{on:{"data":_vm.handleData}})],1),_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.name,"config":{
                                                isRequired: !_vm.$v.form.name.required,
                                                isMaxLength: !_vm.$v.form.name.maxLength,
                                                error: _vm.submitted && _vm.$v.form.name.$error,
                                                errorField: _vm.$t('agency.name')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "name", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.kana_nameCompany'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.kana_name,"config":{
                                                isRequired: !_vm.$v.form.kana_name.required,
                                                isMaxLength: !_vm.$v.form.kana_name.maxLength,
                                                isKatakana: !_vm.$v.form.kana_name.katakana,
                                                error: _vm.submitted && _vm.$v.form.kana_name.$error,
                                                errorField: _vm.$t('agency.kana_nameCompany')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "kana_name", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v("法人番号 ")]),_c('div',{staticClass:"col-sm-6"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.corp_number,"config":{
                                                error: false
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "corp_number", $event)},"change":_vm.inputCorporate}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.phone_number')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.phone_number,"config":{
                                                isNumber: true,
                                                maxlength: 20
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "phone_number", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.postal_code')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.postal_code,"config":{
                                                isNumber: true,
                                                maxlength: 20
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "postal_code", $event)},"input":_vm.inputCode}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.province')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.province,"config":{
                                                isMaxLength: !_vm.$v.form.province.maxLength,
                                                maxlength: 255,
                                                error: _vm.submitted && _vm.$v.form.province.$error,
                                                errorField: _vm.$t('agency.province')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "province", $event)},"input":_vm.inputAddress}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.address_1')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.address_1,"config":{
                                                isMaxLength: !_vm.$v.form.address_1.maxLength,
                                                maxlength: 255,
                                                error: _vm.submitted && _vm.$v.form.address_1.$error,
                                                errorField: _vm.$t('agency.address_1')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "address_1", $event)},"input":_vm.inputAddress}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.address_2')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.address_2,"config":{
                                                placeholder: _vm.$t('pldCommon.address_2'),
                                                isMaxLength: !_vm.$v.form.address_2.maxLength,
                                                maxlength: 255,
                                                error: _vm.submitted && _vm.$v.form.address_2.$error,
                                                errorField: _vm.$t('agency.address_2')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "address_2", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.profile')))]),_c('div',{staticClass:"col-sm-12"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.profile),expression:"form.profile"}],staticClass:"form-control",attrs:{"id":"validationCustom05"},domProps:{"value":(_vm.form.profile)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "profile", $event.target.value)}}})])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.types'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.listTypeAgency),function(item){return _c('div',{key:item.id,staticClass:"custom-control custom-checkbox mr-4 py-1",staticStyle:{"min-width":"100px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.types),expression:"form.types"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":`check${item.id}`},domProps:{"value":item.id,"checked":Array.isArray(_vm.form.types)?_vm._i(_vm.form.types,item.id)>-1:(_vm.form.types)},on:{"change":function($event){var $$a=_vm.form.types,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "types", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "types", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "types", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":`check${item.id}`}},[_vm._v(_vm._s(item.value))])])}),0),_c('span',{class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.types.$error
                                            }}),(_vm.submitted && _vm.$v.form.types.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.types.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('agency.types') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.url')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"validationCustom05","model":_vm.form.url,"config":{
                                                isUrl: !_vm.isUrl,
                                                isMaxLength: !_vm.$v.form.url.maxLength,
                                                maxlength: 255,
                                                error: _vm.submitted && !_vm.isUrl,
                                                errorField: _vm.$t('agency.url')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "url", $event)},"change":_vm.inputUrl}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom12"}},[_vm._v(_vm._s(_vm.$t('agency.searchKeyword')))]),_c('div',{staticClass:"col-sm-12"},[_c('KeyWordTag',{attrs:{"config":_vm.configKeyword}}),(_vm.configKeyword.error)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('agency.searchKeyword') }))+" ")]):_vm._e()],1)])])])])])])]),_c('Footer',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-light mr-3 btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                                path: '/agency/list'
                            })}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(_vm.checkPermission('agency.register') || (_vm.checkPermission('agency.edit') && _vm.$route.query.id))?_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"submit"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.$route.query.id ? _vm.$t('btn.save') : _vm.$t('btn.register'))+" ")]):_vm._e()])])])],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('LoadingIcon')],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }