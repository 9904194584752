var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(!_vm.loading)?_c('div',{staticClass:"row form__body--card form__common--center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-6"},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.nameCompany')))]),_c('div',{staticClass:"col-sm-12"},[_vm._v(" "+_vm._s(_vm.partnerData.name)+" ")])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.department')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.department),expression:"form.department"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.department.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.department)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "department", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.department.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.department.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('agency.department') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.manager'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.name.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('agency.manager') })))]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('agency.manager') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.personChargeKana')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kana_name),expression:"form.kana_name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.kana_name.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.kana_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "kana_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.kana_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.kana_name.katakana && _vm.$v.form.kana_name.maxLength)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('validateField.incorrectFormat', { field: _vm.$t('agency.personChargeKana') })))]):_vm._e(),(!_vm.$v.form.kana_name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('agency.personChargeKana') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.phone_number')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_number),expression:"form.phone_number"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.phone_number.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.phone_number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone_number", $event.target.value)},function($event){return _vm.inputPhone('phone_number')}]}}),(_vm.submitted && _vm.$v.form.phone_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.phone_number.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max20', { field: _vm.$t('agency.phone_number') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.email')))]),_c('div',{staticClass:"col-sm-12"},[_c('InputText',{attrs:{"id":"agency.email","model":_vm.form.email,"config":{
                                                isMaxLength: !_vm.$v.form.email.maxLength,
                                                isEmail: !_vm.$v.form.email.email,
                                                maxlength: 255,
                                                error: _vm.submitted && _vm.$v.form.email.$error,
                                                errorField: _vm.$t('agency.email')
                                            }},on:{"update:model":function($event){return _vm.$set(_vm.form, "email", $event)}}})],1)]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('agency.cellphoneNumber')))]),_c('div',{staticClass:"col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.mobile_number),expression:"form.mobile_number"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.mobile_number.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.mobile_number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "mobile_number", $event.target.value)},function($event){return _vm.inputPhone('mobile_number')}]}}),(_vm.submitted && _vm.$v.form.mobile_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.mobile_number.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max20', { field: _vm.$t('agency.cellphoneNumber') })))]):_vm._e()]):_vm._e()])])])])])])])]),_c('Footer',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-light mr-3 btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                                path: `/agency/view?id=${_vm.$route.query.agency_id}`
                            })}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(_vm.checkPermission('agency.edit') || _vm.checkPermission('agency.edit'))?_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"submit"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.$route.query.id ? _vm.$t('btn.save') : _vm.$t('btn.register'))+" ")]):_vm._e()])])])],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('LoadingIcon')],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }