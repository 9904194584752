<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import Company from '@/components/Company/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import { InputText } from '@/components/Input';
import { keyMaster, masterComputed, masterMethods, agencyMethods, checkPermission, checkPermissionName } from '@/state/helpers';
import { required, maxLength } from 'vuelidate/lib/validators';
import { regexUrl } from '@/utils/format';
import { getAddressByZip, getPostCodeByAddress } from 'postcode-address-get';
import { showMessage } from '@/utils/messages';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: '代理店新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Company,
        Layout,
        PageHeader,
        KeyWordTag,
        Footer,
        InputText
    },
    data() {
        return {
            title: '代理店新規登録',
            index: 1,
            loading: false,
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: this.$route.query.id ? '代理店一覧' : '',
                    href: '/agency/list'
                },
                {
                    text: this.title ? this.title : '代理店新規登録',
                    active: true
                }
            ],
            id: this.$route.query.id,
            form: {
                corp_number: '',
                name: '',
                kana_name: '',
                phone_number: '',
                types: [],
                profile: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                province: ''
            },
            objectData: {},
            isUrl: true,
            listTypeAgency: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            configKeyword: {
                error: false,
                arrayTag: []
            }
        };
    },
    computed: {
        ...masterComputed
    },
    validations: {
        form: {
            name: { required, maxLength: maxLength(255) },
            kana_name: {
                required,
                maxLength: maxLength(255),
                katakana() {
                    if (this.form.kana_name && this.form.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.form.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            postal_code: {
                maxLength: maxLength(20)
            },
            province: { maxLength: maxLength(255) },
            address_1: { maxLength: maxLength(255) },
            address_2: { maxLength: maxLength(255) },
            url: { maxLength: maxLength(255) },
            phone_number: { maxLength: maxLength(20) },
            types: { required }
        }
    },
    watch: {
        objectData: function (newData) {
            console.log(newData);
        }
    },
    beforeRouteUpdate(to, from, next) {
        // Xác định nếu có tham số id trong đường dẫn mới
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
            this.title = '代理店新規登録';
            this.items[2].text = '代理店新規登録';
        }
        // Gọi next để tiếp tục chuyển route
        next();
    },
    mounted() {
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
            this.title = '代理店編集';
            this.items[2].text = '代理店編集';
        }
        this.getListTypeAgency();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...agencyMethods,
        handleData(data) {
            if (data) {
                this.form.name = data.name;
                this.form.corp_number = data.corporate_number;
                this.form.kana_name = data.furigana;
                this.form.postal_code = data.post_code;
                this.form.address_1 = data.city ? data.city.name : '';
                this.form.address_2 = data.street_number;
                this.form.province = data.prefecture ? data.prefecture.name : '';
            }
        },
        inputCorporate() {
            let query = [`method=number`, `number=${this.form.corp_number}`];
            this.findCompany(query.join('&')).then((data) => {
                if (data.code == 200) {
                    this.handleData(data.data[0]);
                }
            });
        },
        resetForm() {
            this.form = {
                name: '',
                kana_name: '',
                phone_number: '',
                types: [],
                profile: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                province: ''
            };
            this.configKeyword.arrayTag = [];
            this.configKeyword.error = false;
        },
        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        inputCode() {
            if (!this.form.postal_code) {
                this.form.province = this.form.address_1 = '';
            } else {
                if (this.form.postal_code.length < 3) return;
                getAddressByZip(this.form.postal_code, (address) => {
                    if (address.status == 200) {
                        this.form.province = address.prefecture;
                        this.form.address_1 = address.city + address.area;
                    }
                });
            }
        },
        inputAddress() {
            getPostCodeByAddress(this.form.province + this.form.address_1, (dataPostcode) => {
                if (dataPostcode.status == 200) this.form.postal_code = dataPostcode.postcode;
            });
        },
        inputUrl() {
            if (this.form.url) {
                this.isUrl = regexUrl(this.form.url);
            } else {
                this.isUrl = true;
            }
        },
        getListTypeAgency() {
            this.listMaster(keyMaster['agency.type']).then((data) => {
                this.listTypeAgency = data;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailAgency(id).then((data) => {
                this.form = data;
                this.loading = false;
                this.configKeyword.arrayTag = data.keywords;
            });
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid || !this.isUrl || this.configKeyword.error) {
                return;
            } else {
                this.register();
            }
        },
        async register() {
            if (this.form.name && !this.loading) {
                this.loading = true;
                let dataObject = Object.assign(this.form);
                dataObject.keywords = this.configKeyword.arrayTag;
                if (this.$route.query.id) {
                    this.updateAgency(dataObject).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '代理店担当者が更新されました。');
                            this.getDetail(this.$route.query.id);
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('agency.name_unique'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            this.loading = false;
                        }
                    });
                } else {
                    this.registerAgency(dataObject).then((data) => {
                        if (data.code == 200) {
                            this.$router
                                .push({
                                    path: '/agency/view',
                                    query: {
                                        id: data.data.id
                                    }
                                })
                                .then(() => {
                                    showMessage(data.code, this.$bvToast, '代理店が登録されました。');
                                });
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('agency.name_unique'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                        }
                        this.loading = false;
                    });
                }
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-6">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05"
                                            >{{ $t('agency.name') }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-sm-12">
                                            <div style="position: absolute; left: 100%; top: 5px">
                                                <company @data="handleData" />
                                            </div>
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.name"
                                                :config="{
                                                    isRequired: !$v.form.name.required,
                                                    isMaxLength: !$v.form.name.maxLength,
                                                    error: submitted && $v.form.name.$error,
                                                    errorField: $t('agency.name')
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05"
                                            >{{ $t('agency.kana_nameCompany') }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.kana_name"
                                                :config="{
                                                    isRequired: !$v.form.kana_name.required,
                                                    isMaxLength: !$v.form.kana_name.maxLength,
                                                    isKatakana: !$v.form.kana_name.katakana,
                                                    error: submitted && $v.form.kana_name.$error,
                                                    errorField: $t('agency.kana_nameCompany')
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">法人番号 </label>
                                        <div class="col-sm-6">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.corp_number"
                                                @change="inputCorporate"
                                                :config="{
                                                    error: false
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.phone_number') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.phone_number"
                                                :config="{
                                                    isNumber: true,
                                                    maxlength: 20
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.postal_code') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.postal_code"
                                                :config="{
                                                    isNumber: true,
                                                    maxlength: 20
                                                }"
                                                @input="inputCode"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.province') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.province"
                                                :config="{
                                                    isMaxLength: !$v.form.province.maxLength,
                                                    maxlength: 255,
                                                    error: submitted && $v.form.province.$error,
                                                    errorField: $t('agency.province')
                                                }"
                                                @input="inputAddress"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.address_1') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.address_1"
                                                :config="{
                                                    isMaxLength: !$v.form.address_1.maxLength,
                                                    maxlength: 255,
                                                    error: submitted && $v.form.address_1.$error,
                                                    errorField: $t('agency.address_1')
                                                }"
                                                @input="inputAddress"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.address_2') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.address_2"
                                                :config="{
                                                    placeholder: $t('pldCommon.address_2'),
                                                    isMaxLength: !$v.form.address_2.maxLength,
                                                    maxlength: 255,
                                                    error: submitted && $v.form.address_2.$error,
                                                    errorField: $t('agency.address_2')
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.profile') }}</label>
                                        <div class="col-sm-12">
                                            <textarea id="validationCustom05" v-model="form.profile" class="form-control"></textarea>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05"
                                            >{{ $t('agency.types') }}
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-sm-12">
                                            <div class="d-flex flex-wrap">
                                                <div
                                                    class="custom-control custom-checkbox mr-4 py-1"
                                                    style="min-width: 100px"
                                                    v-for="item in listTypeAgency"
                                                    :key="item.id"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        v-model="form.types"
                                                        :id="`check${item.id}`"
                                                        :value="item.id"
                                                        class="custom-control-input"
                                                    />
                                                    <label :for="`check${item.id}`" class="custom-control-label">{{ item.value }}</label>
                                                </div>
                                            </div>
                                            <span
                                                :class="{
                                                    'is-invalid': submitted && $v.form.types.$error
                                                }"
                                            ></span>
                                            <div v-if="submitted && $v.form.types.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.types.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('agency.types')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">{{ $t('agency.url') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.url"
                                                :config="{
                                                    isUrl: !isUrl,
                                                    isMaxLength: !$v.form.url.maxLength,
                                                    maxlength: 255,
                                                    error: submitted && !isUrl,
                                                    errorField: $t('agency.url')
                                                }"
                                                @change="inputUrl"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom12">{{ $t('agency.searchKeyword') }}</label>
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" />
                                            <span v-if="configKeyword.error" class="text-danger">
                                                {{
                                                    $t('validateField.max255', {
                                                        field: $t('agency.searchKeyword')
                                                    })
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light mr-3 btn-sm"
                            @click="
                                $router.push({
                                    path: '/agency/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="checkPermission('agency.register') || (checkPermission('agency.edit') && $route.query.id)"
                            type="submit"
                            @click="formSubmit"
                            class="btn btn-primary ml-3"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
